<template>
  <div class="actions">
    <div class="order-pause-status">
      <button class="action-btn pause-btn" v-if="!ordersPaused" @click="pauseOrders(true)">
        {{$t('PAUSE_ORDERS')}}
      </button>
      <button v-else class="action-btn unpause-btn" @click="pauseOrders(false)">
        TAUKOTILA PÄÄLLÄ
      </button>
      <button class="action-btn reports-btn" @click="viewReports">
        Raportit
      </button>
      <button class="action-btn logout-btn" @click="logout">
        Kirjaudu ulos
      </button>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  computed: {
    ...mapState("orders", ["ordersPaused"]),
  },
  methods: {
    viewReports() {
      this.$f7router.navigate("/reports/");
    },
    logout() {
      this.$store.dispatch("auth/logout");
    },
    pauseOrders(pause) {
      this.$store.dispatch("orders/setOrdersPaused", pause);
    },
  },
};
</script>

<style scoped>
.actions {
  max-width: 768px;
  margin: 0 auto;
  margin-bottom: 25px;
}

.action-btn {
  font-weight: bold;
  padding: 14px 4px;
  border: 0;
}

.action-btn:not(:first-child) {
  margin-top: 15px;
}

.logout-btn {
  background: #fff;
}

.reports-btn {
  background: #ee7101;
  color: #fff;
}

@keyframes unpause-btn-anim {
  0% {
    background: #ff3c3c;
    border-color: #ff3c3c;
  }

  100% {
    background: rgb(155, 21, 21);
    border-color: rgb(155, 21, 21);
  }
}

.unpause-btn {
  box-sizing: border-box;
  color: #fff;
  background: #ff3c3c;
  border: 2px solid;
  border-color: #ff3c3c;
  animation: unpause-btn-anim 2s linear infinite alternate;
}

.pause-btn {
  box-sizing: border-box;
  border: 2px solid transparent;
  color: #fff;
  background: #ee7101;
}
</style>
