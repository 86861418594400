<template>
  <div class="order">
    <RejectModal v-if="showRejectModal" :order="order" @close="showRejectModal = false" />
    <div class="info" @click="goToOrder">
      <span class="time">{{order.createdAt | date}}</span>
      <div class="order-id">#{{order.id}}</div>
    </div>
    <div class="items">
      <span class="pickup" :class="{alert: isImminent}">{{$t('PICKUP')}} {{order.time | date}}</span>
      <span class="customer-detail" v-if="order.customer">
        <span class="customer-detail-label">{{$t('CUSTOMER')}}:</span>
        {{order.customer.firstName}} {{order.customer.lastName}}
        -
        {{order.customer.phone}}
      </span>

      <div class="order-item" v-for="item in order.items">
        <span class="product-name product-name-main">{{item.quantity}}x {{item.name}} - {{item.total | price}}€</span>
        <span class="extra" v-for="extra in item.extras">
          +{{extra.name}} - {{extra.total | price}}€
        </span>
        <span v-if="hasRewards(item)" class="item-info">
          Käytetyt edut: {{formatAppliedRewards(item)}}
        </span>
        <span class="item-info" v-if="item.info">{{$t('ADDITIONAL_INFO')}}: {{item.info}}</span>
      </div>

      <!--
      <div class="order-item" v-if="rewards.length">
        {{$t('REWARDS')}}: {{rewards | rewards}}
      </div>
      -->

      <div class="order-total">
        Yhteensä {{order.total | price}}€
      </div>
    </div>
    <div class="actions">
      <template v-if="order.status === 'pending'">
        <button @click="accept" class="btn-positive">{{$t('ACCEPT_ORDER')}}</button>
        <button @click="reject" class="btn-secondary">{{$t('REJECT_ORDER')}}</button>
      </template>

      <template v-if="order.status === 'accepted'">
        <button @click="setStatus('processing')" class="btn-positive">{{$t('ORDER_SET_WIP')}}</button>
      </template>

      <template v-if="order.status === 'processing'">
        <button @click="setReady" class="btn-positive">{{$t('ORDER_SET_READY')}}</button>
      </template>
    </div>
  </div>
</template>

<script>
import RejectModal from "./RejectModal";
import {ErrorMixin} from "@/mixins";

export default {
  mixins: [ErrorMixin],
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  components: {
    RejectModal,
  },
  data: () => ({
    showRejectModal: false,
  }),
  filters: {
    price: v => {
      const num = parseFloat(v);
      return num.toFixed(2).replace(".", ",");
    },
    rewards: v => v.map(i => i.reward.name).join(", "),
  },
  computed: {
    isImminent() {
      const orders = this.$store.getters["orders/imminentIds"];

      return orders.includes(this.order.id);
    },
    rewards() {
      const rewards = [];
      if (Array.isArray(this.order.rewards)) {
        rewards.push(...this.order.rewards);
      }

      if (Array.isArray(this.order.passiveRewards)) {
        rewards.push(...this.order.passiveRewards);
      }

      return rewards;
    },
  },
  methods: {
    hasRewards(item) {
      if (item.appliedRewards.length > 0) return true;

      for (const extra of item.extras) {
        if (extra.appliedRewards.length > 0) return true;
      }

      return false;
    },
    formatAppliedRewards(item) {
      const rewards = [...item.appliedRewards];
      for (const extra of item.extras) {
        rewards.push(...extra.appliedRewards);
      }

      return rewards.map(r => r.label).join(", ");
    },
    confirm() {
      return new Promise(resolve => {
        this.$f7.dialog.alert("Muistithan lisätä ostoksen kassaan", "Huomio", resolve);
      });
    },
    async setReady() {
      await this.setStatus("ready");
      await this.confirm();
    },
    async setStatus(status) {
      this.$f7.dialog.preloader("");
      try {
        await this.$store.dispatch("orders/updateStatus", {id: this.order.id, status});
      } catch (e) {
        this.displayError(e);
      } finally {
        this.$f7.dialog.close();
      }
    },
    goToOrder() {
      this.$f7router.navigate(`/orderInfo/${this.order.id}`);
    },
    async accept() {
      this.$f7.dialog.preloader("");
      try {
        await this.$store.dispatch("orders/accept", this.order.id);
      } catch (e) {
        this.displayError(e);
        this.$store.dispatch("orders/load");
      } finally {
        this.$f7.dialog.close();
      }
    },
    reject() {
      this.showRejectModal = true;
    },
  },
};
</script>

<style scoped>

  .order-total {
    margin-top: 15px;
    font-size: 16px;
    font-weight: 600;
  }

  .order {
    font-size: 14px;
    color: #fff;
    padding: 5px 15px 15px 15px;
    display: flex;
    justify-content: space-between;
    background-color: #000;
    margin-bottom: 20px;
  }

  .time {
    font-weight: 600;
    padding-bottom: 10px;
    display: block;
  }

  .info {
    width: 20%;
  }

  .items {
    padding-top: 10px;
    width: 50%;
  }

  .actions {
    padding-top: 25px;
    width: 25%;
    align-items: flex-end;
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
  }

  .pickup {
    font-size: 16px;
    font-weight: 900;
    display: block;
    position: relative;
  }

  .pickup.alert {
    color: #cf1414;
  }

  .customer-detail-label {
    font-size: 16px;
    font-weight: 900;
  }

  .customer-detail {
    font-size: 16px;
    display: block;
    margin-bottom: 15px;
  }

  .product-name {
    font-weight: 500;
    font-size: 15px;
    display: block;
  }

  .extra {
    display: block;
    margin-left: 8px;
  }

  .item-info {
    margin-left: 8px;
    display: block;
  }

  .order-item {
    margin-bottom: 10px;
  }

  .order-id {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    background: #23aa02;
    height: 80px;
    font-size: 18px;
    width: 100%;
    max-width: 128px;
  }

  .btn-positive {
    cursor: pointer;
    height:90px;
    width:130px;
    color: #fff;
    background-color: #ee7101;
    font-weight: 600;
    font-size: 15px;
    padding: 7px;
    border: none;
    line-height: 1.5;
  }

  .btn-secondary {
    cursor: pointer;
    border: none;
    background-color: transparent;
    color: #ee7101;
    width: 130px;
    font-weight: bold;
    padding: 10px 0;;
  }
</style>
