


















































.loyalty-logo {
  width: 100px;
}

.info-wrapper {
  max-width: 768px;
  margin: 0 auto;
  display: flex;
}

.loyalty-logo-container {
  width: 20%;
}

.customer-information {
  color: #fff;
  display: flex;
  flex-direction: column;
}

.rewards {
  max-width: 768px;
  margin: 0 auto;
  width: 100%;
  margin-top: 40px;
}

.name, .phone, .lvl-name, .coconuts {
  font-weight: 600;
}

.name {
  font-size: 20px;
}

.phone {
  font-size: 14px;
}

.lvl-name {
  margin-top: 10px;
  font-size: 16px;
}

.coconuts {
  color: #23aa02;
}
