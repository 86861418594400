<template>
  <nav class="app-nav">
    <ul class="tabs">
      <li class="tab" :class="tabClass('pending')" @click="setTab('pending')">
        <span class="tab-name">{{$t('ORDERS_NEW')}}</span>
        <span class="counter">{{pending.length}}</span>
      </li>
      <li class="tab" :class="tabClass('accepted')" @click="setTab('accepted')">
        <span class="tab-name">{{$t('ORDERS_QUEUED')}}</span>
        <span class="counter">{{accepted.length}}</span>
      </li>
      <li class="tab" :class="tabClass('processing')" @click="setTab('processing')">
        <span class="tab-name">{{$t('ORDERS_PROCESSING')}}</span>
        <span class="counter">{{processing.length}}</span>
      </li>
      <li class="tab" :class="tabClass('ready')" @click="setTab('ready')">
        <span class="tab-name">{{$t('ORDERS_READY')}}</span>
        <span class="counter">{{ready.length}}</span>
      </li>
    </ul>
  </nav>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  data: () => ({
    currentTab: "pending",
  }),
  computed: {
    ...mapGetters("orders", ["pending", "processing", "ready", "accepted", "imminent"]),
  },
  methods: {
    tabClass(tab) {
      const active = this.currentTab === tab;
      let alert = false;
      if (tab === "accepted") {
        alert = this.imminent.length > 0;
      }

      return {active, alert};
    },
    setTab(tab) {
      this.currentTab = tab;
      this.$emit("changeTab", tab);
    },
  },
};
</script>

<style scoped>

.tabs {
  padding: 0;
  max-width: 768px;
  margin: 0 auto;
  list-style: none;
  display: flex;
  flex-direction: row;
}

.tab {
  user-select: none;
  cursor: pointer;
  background-color: #0e5727;
  color: #fff;
  height: 80px;
  flex: 1;
  display: block;
  display: flex;
  align-items: center;
  transition: ease-in 50ms;
  padding: 0 15px;
  justify-content: space-between;
  font-size: 16px;
  transition: 100ms ease-in;
}

.tab:not(:last-child) {
  margin-right: 20px;
}

.tab.active {
  background-color: #2a864a;;
}

.tab.alert > .counter {
  background-color: #ff0000;
}

.tab:hover {
  background-color: #2a864a;
}

.tab-name {
  font-weight: 900;
  text-transform: uppercase;
  font-size: 12px;
}

.counter {
  display: block;
  background-color: #23aa02;
  color: #fff;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 14px;
}
</style>
