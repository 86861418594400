<template>
  <header class="app-header">
    <div class="logo-container">
      <img src="@img/JJB-ROUND-RGB.svg" class="logo">
    </div>

    <Navigation v-if="showNavigation" @changeTab="$emit('changeTab', $event)" />
  </header>
</template>

<script>
import Navigation from "./Navigation";

export default {
  components: {
    Navigation,
  },
  props: {
    showNavigation: Boolean,
  },
};
</script>

<style scoped>
.app-header {
  background-image: url(~@/img/leafs.svg);
  background-repeat: no-repeat;
  background-position: top right
}

.logo {
  margin-top: 45px;
  height: 135px;
  width: 135px;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 238px;
}
</style>
