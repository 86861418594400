<template>
  <div class="rewards">
    <h2 class="rewards-title">{{$t('REWARDS_TO_USE')}}</h2>
    <ul class="rewards-list" ref="listContainer">
      <li v-for="reward in usableRewards" :key="reward.id">
        <button class="reward-button" @click="toggleReward(reward)">
          <span class="reward-button-validity">Voimassa {{rewardValidity(reward.expiresAt)}}</span>
          <span class="reward-button-name">
            {{reward.reward.name}}
          </span>
          <span class="reward-button-selection" :class="{visible: isRewardSelected(reward)}">
            &check;
          </span>
        </button>
      </li>
    </ul>
    <button class="redeem-rewards" @click="redeemSelected" :disabled="!canRedeem">
      {{$t('USE_SELECTED_REWARDS')}}
    </button>

    <h2 class="rewards-title">{{$t('OTHER_REWARDS')}}</h2>
    <span class="other-rewards">{{otherRewards}}</span>
  </div>
</template>

<script>
import {ErrorMixin} from "@/mixins";
import {format as formatDate} from "date-fns";

export default {
  mixins: [ErrorMixin],
  data: () => ({
    selectedRewards: {},
  }),
  props: {
    columns: {
      type: Number,
      default: 2,
    },
    customer: {
      required: true,
      type: Object,
    },
  },
  computed: {
    otherRewards() {
      return this.passiveRewards.map(r => r.reward.name).join(", ");
    },
    canRedeem() {
      const valid = Object.values(this.selectedRewards).filter(v => v);
      return valid.length > 0;
    },
    rewards() {
      return this.customer.rewards;
    },
    passiveRewards() {
      return this.rewards.filter(r => r.reward.passive);
    },
    usableRewards() {
      return this.rewards.filter(r => !r.reward.passive);
    },
  },
  mounted() {
    this.$nextTick(() => this.setColumns(this.columns));
  },
  methods: {
    rewardValidity(d) {
      const date = new Date(d);
      return formatDate(date, "dd.MM.yyyy");
    },
    confirm(title, msg) {
      return new Promise(resolve => {
        this.$f7.dialog.confirm(msg, title, () => resolve(true), () => resolve(false));
      });
    },
    redeemSelected() {
      this.$f7.dialog.alert(
        "Etujen käyttö kassan web-sovelluksesta ei ole enää mahdollista. Asiakkaan tulee käyttää etu sovelluksesta.",
        "Virhe"
      );
    },
    removeRewards(ids) {
      for (const id of ids) {
        const index = this.customer.rewards.findIndex(r => r.id === id);
        if (index < 0) continue;
        this.customer.rewards.splice(index, 1);
      }
    },
    toggleReward(reward) {
      const id = reward.id;
      const current = Boolean(this.selectedRewards[id]);
      this.$set(this.selectedRewards, id, !current);
    },
    isRewardSelected(reward) {
      return Boolean(this.selectedRewards[reward.id]);
    },
    setColumns(n) {
      const el = this.$refs.listContainer;
      el.style.setProperty("--reward-list-columns", n);
    },
  },
};
</script>

<style scoped>

.other-rewards {
  display: block;
  font-size: 14px;
  color: var(--reward-text-color, #fff);
}

.rewards-title {
  font-size: 16px;
  color: var(--reward-title-color, #fff);
  margin: 5px 0;
  padding: 0;
  font-weight: 800;;
}

.rewards-list {
  padding: 0;
  margin: 0 0 20px 0;
  list-style: none;
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(var(--reward-list-columns, 1), 1fr);
}

.redeem-rewards {
  font-weight: 600;
  border: none;
  background: #ee7101;
  padding: 15px;
  text-align: center;
  font-size: 14px;
  color: #fff;
  transition: 100ms ease-in;
}

.redeem-rewards:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.reward-button {
  font-weight: 600;
  border: none;
  background: #27b301;
  color: #fff;
  padding: 15px;
  display: grid;
  font-size: 14px;
  position: relative;
  grid-template-columns: 1fr min-content;
  align-items: center;
}

.reward-button-name {
  grid-row: 2;
  grid-column: 1;
}

.reward-button-validity {
  font-size: 12px;
  grid-row: 1;
  grid-column: 1 / span 2;
}

.reward-button-selection {
  opacity: 0;
  transition: 100ms ease-in;
  grid-row: 2;
  grid-column: 2;
}

.reward-button-selection.visible {
  opacity: 1;
}
</style>
