<template>
  <label class="radio-button">
    <input type="radio" @change="change" :checked="currentValue === value">
    <span class="radio--indicator" />
    <span class="radio--label">{{label}}</span>
  </label>
</template>

<script>
export default {
  model: {
    prop: "currentValue",
    event: "change",
  },
  inheritAttrs: false,
  props: {
    currentValue: undefined,
    value: undefined,
    label: String,
  },
  methods: {
    change() {
      this.$emit("change", this.value);
    },
  },
};
</script>

<style scoped>

.radio-button {
  display: block;
}

.radio-button > input {
  display: none;
}

.radio--indicator {
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: inline-block;
  background: #fff;
  color: #fff;
  transition: 100ms linear;
}

.radio--indicator::before {
  display: block;
  text-align: center;
  font-size: 18px;
  line-height: 18px;
  content: "checkbox_ios";
  font-family: "framework7-core-icons";
}

.radio-button > input:checked + .radio--indicator {
  background: #27B301;
}

.radio--label {
  color: #fff;
}

</style>
