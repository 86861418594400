<template>

  <div class="table-wrapper">
    <table class="report-table">
      <tr>
        <th>Tilaus#</th>
        <th>Aika</th>
        <th>Asiakas</th>
        <th>Summa</th>
        <th>Tuotekategoria</th>
        <th>Tuote</th>
        <th>Koko</th>
        <th>Alennus</th>
        <th>Alennuksen nimi</th>
        <th>Hylkäyssyy</th>
        <th>Hylkäysaika</th>
      </tr>
      <tr v-for="row in data">
        <td>{{row.orderId}}</td>
        <td>{{row.time}}</td>
        <td>{{row.customer}}</td>
        <td>{{row.total}} €</td>
        <td>{{row.productCategory}}</td>
        <td>{{row.productName}}</td>
        <td>{{row.productSize}}</td>
        <td>{{row.discount}}</td>
        <td>{{row.discountName}}</td>
        <td>{{row.rejectReason}}</td>
        <td>{{row.rejectTime}}</td>
      </tr>
      <tr>
        <th colspan="2">YHTEENSÄ</th>
        <td colspan="9">{{totals}} €</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    data: Array,
  },
  computed: {
    totals() {
      const total = this.data.reduce((sum, row) => sum + Number(row.total) * 100, 0);
      return total / 100;
    },
  },
};
</script>

<style scoped>
.table-wrapper {
  position: relative;
  width: 100%;
  overflow-x: scroll;
}

.report-table {
  background-color: #fff;;
  border-collapse: collapse;
  border: 1px solid #000;
}

.report-table td,
.report-table th {
  border: 1px solid #000;
  padding: 7px;
  white-space: nowrap;
}
</style>
