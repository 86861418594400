













































































.languages {
  display: flex;
  flex-direction: row;

  max-width: 200px;
  margin: 0 auto;
  justify-content: space-around;
}

.lang-btn {
  height: 40px;
  padding: 0;
  width: 62px;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  border: 0;
}

.lang-btn.fi {
  background-image: url(~@/img/fi.svg);
}

.lang-btn.sv {
  background-image: url(~@/img/sv.svg);
}

.button:not(.button-small) {
  margin: 1vw;
  background: #23aa02;
}
.block-title,
.button-small {
  color: white;
}
.login-outer-container{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.login-inner-container {
  background: #0e5727;
  width: 100%;
  max-width: 500px;
}
