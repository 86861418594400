<template>
  <f7-popup ref="popup" :close-by-backdrop-click="false" :close-on-escape="false" class="reject-modal">
    <div class="reject-modal-inner">
      <div class="row close-row">
        <button class="close-btn" @click="close" />
      </div>

      <div class="row row-heading">{{$t('REJECT_ORDER')}}</div>
      <div class="row row-info">
        {{$t('PICKUP')}} {{order.time | date}}
      </div>
      <div class="row row-info" v-for="item in order.items">
        {{item.name}}
      </div>

      <div class="row send-row">
        <button :disabled="reason === null" class="send-btn" @click="send">Hylkää tilaus</button>
      </div>
    </div>
  </f7-popup>
</template>

<script>
export default {
  props: {
    order: {
      required: true,
    },
  },
  mounted() {
    this.$refs.popup.open();
  },
  methods: {
    async send() {
      this.$refs.popup.close();
      this.$f7.dialog.preloader("");
      try {
        await this.$store.dispatch("orders/reject", {id: this.order.id, reason: this.reason});
      } finally {
        this.$f7.dialog.close();
      }
    },
    close() {
      this.$refs.popup.close();
      this.$emit("close");
    },
    setReason(reason) {
      this.reason = reason;
    },
  },
  data: () => ({
    reason: "RUSH",
    reasons: [
      {label: "Raaka-aine loppu", value: "NO_INGREDIENT"},
      {label: "Sulkemassa", value: "CLOSING"},
      {label: "Kiire", value: "RUSH"},
    ],
  }),
};
</script>

<style scoped>
  .reject-modal {
    max-width: 530px;
    background-color: black;
  }

  .reject-modal-inner {
    background: url(~@/img/viidakko_web.svg) -480px -100px no-repeat, url(~@/img/viidakko_web2.svg) center bottom repeat;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .close-row {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
  }

  .close-btn {
    width: 15px;
    height: 15px;
    background-color: transparent;
    border: none;
    background-image: url(~@/img/close.svg);
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
  }

  .row-heading {
    font-weight: 800;
    font-size: 18px;
    color: #fff;
    text-transform: uppercase;
    justify-content: center;
    font-family: 'Open Sans', sans-serif;
    padding-bottom: 22px;
  }

  .row-info {
    margin: .5em 0;
    font-family: 'Open Sans', sans-serif;
    color: #fff;
    font-weight: 800;
    font-size: 14px;
    justify-content: center;
  }

  .radio-btn {
    background-color: transparent;
  }

  .reason-list {
    --f7-list-bg-color: transparent;
    --f7-list-item-title-text-color: #fff;
    --f7-radio-active-color: transparent;
    --f7-list-item-title-font-size: 14px;
    display: flex;
    justify-content: center;
  }

  .reason-list >>> ul {
    max-width: 450px;
    width: 100%;
  }

  .reason-list >>> .icon-radio {
    background: #fff;
    border: #fff;
    margin-right: 0;
  }

  .reason-list >>> .icon-radio::after {
    content: "\2713";
    color: #fff;
    top: 3px;
    left: 9px;
    background: transparent;
  }

  .reason-list >>> input[type="radio"]:checked ~ .icon-radio {
    background: #23aa02;
    border: #23aa02;
  }

  .send-row {
    justify-content: center;
    margin-top: 100px;
  }

  .send-btn:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  .send-btn {
    font-family: 'Open Sans', sans-serif;
    color: #fff;
    height: 40px;
    cursor: pointer;
    background: #2b6032;
    border: 0;
    border-radius: 30px;
    font-weight: 800;
    font-size: 16px;
    max-width: 250px;
  }
</style>
