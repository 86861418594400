<template>
  <div class="customer-card">

    <div class="customer-card-info">
      <h4 class="info-heading">{{$t('CURRENT_CUSTOMER')}}</h4>
      <template v-if="!minimized">
        <span class="customer-info-text name">{{customer.firstName}} {{customer.lastName}}</span>
        <span class="customer-info-text">{{customer.phone}}</span>
        <span class="customer-info-text coconuts">{{customer.loyalty.points}} kookosta</span>
      </template>
    </div>

    <div class="customer-card-rewards" v-if="!minimized">
      <Rewards :customer="customer" :columns="3" />
    </div>

    <div class="customer-card-actions">
      <button class="action-btn minimize" @click="toggleMinimized">
        <template v-if="minimized">
          Näytä
        </template>
        <template v-else>
          Piilota
        </template>
      </button>
      <button class="action-btn close" @click="$emit('close')">Sulje</button>
    </div>
  </div>
</template>

<script>
import Rewards from "@/components/Rewards.vue";

export default {
  components: {
    Rewards,
  },
  data: () => ({
    minimized: false,
  }),
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },
  methods: {
    toggleMinimized() {
      this.minimized = !this.minimized;
    },
    open() {
      this.$f7router.navigate("/customer/", {props: {customer: this.customer}});
    },
  },
};
</script>

<style scoped>
.customer-card {
  --reward-title-color: #000;
  --reward-text-color: #000;

  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  max-width: 768px;
  background: #fff;
  padding: 20px 15px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.customer-card-actions {
  display: flex;
  flex-direction: column;
}

.action-btn {
  width: auto;
  outline: none;
  border: 0;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  padding: 15px;
  margin-bottom: 5px;
}

.action-btn.minimize {
  background: #0e5727;
}

.action-btn.close {
  background: #ee7101;
}

.loyalty-wrapper {
  width: 20%;
  display: flex;
  justify-content: flex-start;
}

.loyalty-info {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.loyalty-image {
  max-width: 70px;
}

.loyalty-level {
  font-weight: 800;
}

.customer-card-info {
  font-weight: 800;
  font-size: 16px;
  display: flex;
  flex-direction: column;
}

.customer-info-text {
  margin-bottom: 0px;
  font-size: 14px;
}

.customer-info-text.name {
  font-size: 20px;
}

.customer-info-text.coconuts {
  color: #23aa02;
}

.info-heading {
  margin: 0;
  margin-bottom: 4px;
  font-size: 24px;
}

</style>
