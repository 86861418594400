<template>
  <div class="queue-view">
    <div class="inner-view">
      <div class="no-orders" v-if="!items.length">{{$t('NO_ORDERS')}}</div>
      <Order v-for="order in items" :key="order.id" :order="order" />
    </div>
  </div>
</template>

<script>
import Order from "./Order";

export default {
  components: {
    Order,
  },
  props: {
    items: {
      required: true,
    },
  },
};
</script>

<style scoped>

  .no-orders {
    text-align: center;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    padding-top: 40px;
  }

  .inner-view {
    max-width: 768px;
    margin: 0 auto;
  }
</style>
