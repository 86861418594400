<template>
  <f7-page>
    <div class="page-wrapper">
      <Header />
      <div class="page-content">

        <div class="report">
          <button @click="goBack" class="btn-return">Takaisin tilauksiin</button>
          <h2 class="report-heading">Näytä raportti</h2>
          <div class="report-options">
            <Radio v-model="reportType" value="currentDay" label="Nykyiseltä päivältä" />
            <Radio v-model="reportType" value="currentMonth" label="Nykyiseltä kuukaudelta" />
            <Radio v-model="reportType" value="date" label="Tietyltä päivältä" />
            <Radio v-model="reportType" value="range" label="Valitulta aikajaksolta" />
          </div>

          <div class="report-extra-options">
            <label class="extra-option" v-if="reportType === 'date'">
              <span class="extra-option-label">Päivämäärä</span>
              <input v-model="specificDate" type="date">
            </label>

            <template v-if="reportType === 'range'">
              <label class="extra-option">
                <span class="extra-option-label">Alku</span>
                <input v-model="startDate" type="date">
              </label>
              <label class="extra-option">
                <span class="extra-option-label">Päättyminen</span>
                <input v-model="endDate" type="date">
              </label>
            </template>
          </div>

          <div class="report-actions">
            <button @click="download" :disabled="!canShow" class="btn-view">Näytä raportti</button>
          </div>

          <ReportTable v-if="reportData" :data="reportData" />
        </div>
      </div>
    </div>
  </f7-page>
</template>

<script>
import Radio from "@/components/Radio";
import ReportTable from "@/components/ReportTable";
import Header from "@/components/Header";
import * as dateFn from "date-fns";

export default {
  components: {
    Header,
    Radio,
    ReportTable,
  },
  data: () => ({
    busy: false,
    reportType: null,
    reportData: null,
    specificDate: null,
    startDate: null,
    endDate: null,
  }),
  computed: {
    canShow() {
      if (this.reportType === "currentDay") return true;
      if (this.reportType === "currentMonth") return true;
      if (this.reportType === "date" && this.specificDate) return true;
      if (this.reportType === "range" && this.startDate && this.endDate) return true;

      return false;
    },
  },
  methods: {
    goBack() {
      this.$f7router.back();
    },
    getOptions() {
      const FORMAT = "yyyy-MM-dd";
      if (this.reportType === "currentDay") {
        const now = dateFn.format(new Date(), FORMAT);
        return {
          start: now,
          end: now,
        };
      } else if (this.reportType === "currentMonth") {
        const now = new Date();
        const start = dateFn.startOfMonth(now);
        const end = dateFn.endOfMonth(now);
        return {
          start: dateFn.format(start, FORMAT),
          end: dateFn.format(end, FORMAT),
        };
      } else if (this.reportType === "date") {
        return {start: this.specificDate, end: this.specificDate};
      } else if (this.reportType === "range") {
        return {
          start: this.startDate,
          end: this.endDate,
        };
      }
    },
    async download() {
      if (this.busy) return;

      this.busy = true;
      const opt = this.getOptions();
      this.$f7.preloader.show();
      try {
        this.reportData = await this.$store.dispatch("orders/loadReport", opt);
        this.$f7.preloader.hide();
      } catch (e) {
        this.$f7.preloader.hide();
        this.$f7.dialog.alert("Jokin meni pieleen. Yritä hetken kuluttua uudelleen.");
      } finally {
        this.busy = false;
      }
    },
  },
};
</script>

<style scoped>
.page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.page-content {
  padding-top: 20px;
  background-color: #2a864a;
  flex: 1;
}

.report {
  max-width: 768px;
  margin: 0 auto;
  margin-bottom: 25px;
}

.report-heading {
  margin: 0 0 10px 0;
  color: #fff;
}

.report-actions {
  max-width: 400px;
  margin-bottom: 15px;
}

.btn-view {
  font-weight: bold;
  padding: 14px 4px;
  border: 0;
  background: #ee7101;
  color: #fff;
}

.btn-return {
  padding: 4px 4px;
  max-width: 200px;
  font-weight: bold;
  border: 0;
  background: #ee7101;
  color: #fff;

  margin-bottom: 30px;
}

.report-options {
  max-width: 400px;
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 15px;
}

.extra-option {
  display: block;
  color: #fff;
  margin-bottom: 15px;
}

.extra-option > .extra-option-label {
  display: inline-block;
  min-width: 100px;
}

.extra-option > input {
  display: inline-block;
  color: #000;
  background: #fff;
  padding: 5px;
}
</style>
