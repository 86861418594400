<template>
  <f7-page class="welcome-page">

    <div class="action-container">
      <button @click="proceed" class="continue-button">{{$t('CONTINUE_APP')}}</button>
    </div>

  </f7-page>
</template>

<script>
import logger from "@/loggerInstance";

export default {
  created() {
    logger.log("INFO", "Welcome.vue");
  },
  methods: {
    proceed() {
      this.$f7router.navigate("/orders/", {clearPreviousHistory: true, reloadAll: true});
      logger.log("INFO", "Welcome.vue proceed()");
    },
  },
};
</script>

<style scoped>
.action-container {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.continue-button {
  width: 200px;
  padding: 20px 20px;
  font-weight: bold;
  box-sizing: border-box;
  color: #fff;
  background: #ee7101;
  border: 2px solid #ee7101;
  font-size: 20px;
}

</style>
