<template>
  <div class="alert-display" ref="el" />
</template>
<script>

import {mapGetters} from "vuex";
import alertSound from "@/assets/alert2.mp3";

const audioAlert = new Audio(alertSound);

const keyframes = [
  {backgroundColor: "rgba(42, 134, 74, 0)"},
  {backgroundColor: "rgba(42, 134, 74, 1)"},
  {backgroundColor: "rgba(42, 134, 74, 0)"},
];

export default {
  data: () => ({
    interval: null,
    handledOrders: new Set(),
    handledImminentOrders: new Set(),
  }),
  computed: {
    ...mapGetters("orders", {
      pendingOrders: "pending",
      acceptedOrders: "accepted",
    }),
  },
  watch: {
    pendingOrders(orders) {
      let shouldAlert = false;

      for (const order of orders) {
        const handled = this.handledOrders.has(order.id);
        if (!handled) {
          shouldAlert = true;
          this.handledOrders.add(order.id);
        }
      }

      if (shouldAlert) {
        this.alert();
      }
    },
  },
  created() {
    this.interval = setInterval(this.update, 60000);
    this.update();

    console.log("NewOrderAlert created");
  },
  beforeDestroy() {
    console.log("NewOrderAlert beforeDestroy");
    clearInterval(this.interval);
  },
  methods: {
    hasImminentOrders() {
      const imminentOrders = this.$store.getters["orders/imminent"];
      if (imminentOrders.length === 0) return false;

      let hasUnhandledOrders = false;

      for (const order of imminentOrders) {
        if (!this.handledImminentOrders.has(order.id)) {
          hasUnhandledOrders = true;
        }

        this.handledImminentOrders.add(order.id);
      }

      return hasUnhandledOrders;
    },
    update() {
      let hasAlert = false;
      if (this.pendingOrders.length > 0) {
        hasAlert = true;
      }

      if (this.hasImminentOrders()) {
        hasAlert = true;
      }

      if (hasAlert) this.alert();
    },
    alert() {
      if (this.$refs.el) {
        this.$refs.el.animate(keyframes, {duration: 1000});
      }
      audioAlert.play();
    },
  },
};
</script>

<style scoped>
.alert-display {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
</style>
