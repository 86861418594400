.page-content {
  background: black;
}

.page-content * img{
	max-width:100%;
}

.ios .theme-dark .panel{
	background:black;
}

.active-link .nav-button{
	background-color: #2a864a;
}

.list-view{
	background-color: #2a864a!important;
	}
	.customer-info {
		padding-top:20px !important;
		padding-bottom: 0px !important;

		.list-item-single-outer {

			.list-item-single {

				background: white;
				height: 130px;

				.id-col {
					display: flex;
					padding-left: 15px;

					.lion-container {
						display: inline-flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;

						h4 {
							color: black;
							margin: 0;
						}

					}

				}

				.text-col {
					text-align: left;

					.title {
						padding-bottom: 10px;
					}

					.name {
						text-transform: uppercase;
						padding-bottom: 5px;
					}

					h3 {
						color: black;
					}
				}

			}

		}

  }

.list-container{
  padding-top:0px;
  padding-bottom:120px;
}

.list-item-single-outer{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.list-item-single{
  padding-left:0px!important;
  padding-right: 0px!important;
  padding-top:20px!important;
  padding-bottom:5px!important;
  background-color: black;
  margin-bottom:20px!important;
  width:calc(100% - 20px);

  .list-item-single{
    padding-top:0px!important;
    box-shadow: none;
  }
  .list-item-single .description{
    color: white;
		padding-top: 10px;
		text-align: left;
		line-height: 1em;
  }

  .list-container {
      padding-top: 0px;
      padding-bottom: 0px;
  }

  .text-col{
    width:50%!important;
  }

  h3 {
		margin: 0;
		text-align: left;
    color: white;

    span {
      float: right;
    }
  }

}

.id-col{
  width:20%!important;
}

.text-col{
  width:55%!important;
  padding-top:0px;
  padding-left:7px;
  padding-right:7px;
}

.button-col{
  width: 25% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin-top: 15px;
  padding-right: 15px;
}

.no-padding.order-time.col-100 {
  position: absolute;
  top: -10px;
  left: 15px;
  color: white;
  text-align: left;
}
.order-time{
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom:12px;
}

.order-number{

}

.order-ticket{
  background: #23aa02;
  width:100%;
  max-width: 128px;
  height:80px;
  color:white;
  font-size:18px;
  font-weight: 900;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  margin-top: 21px!important;
}

.green-button{
  height:90px;
  width:130px;
  color:white;
  background-color: #ee7101;
  font-weight: 600;
  font-size: 15px;
  padding: 7px;
  border: none;
  line-height: 1.5;
}

.green-button-container {
  text-align: center;

  .reject {
    color: #ee7101;
    padding: 10px 0px 10px 0px;
    font-weight: bold;
  }
}

.list-item-single-outer a{
  color:black;
}

.queue-empty-text.no-padding.block {
  height: 200px;
  height:calc(100vh - 340px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #848484;
  font-size: 24px;
  text-transform: uppercase;
}

.single-order .no-padding, .single-order.no-padding{
  padding-top:0;
  padding-bottom:0;
  padding-left:0;
  padding-right:0;
  margin-top:0;
  margin-bottom:0;
  color: white;
}

.single-order.outer-container{
  padding-top:0;
  padding-bottom:0;
  padding-left:0;
  padding-right:0;
  width:100%;
  background-color: black;
  margin-top:0;
  margin-bottom:0;
}

.single-order.advantages{
  background-color: black !important;
}

.single-order .inner-container{
  padding-top:0;
  padding-bottom:0;
  padding-left:0;
  padding-right:0;
  max-width:768px;
  width:100%;
  margin-left:auto;
  margin-right:auto;
  margin-top:0;
  margin-bottom:0;
}

.single-order .list-view{
  background-color:white;
}

.single-order .list-container{
  padding-top:32px;
  padding-bottom:40px;
}

.single-order .list-item-single-outer{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.single-order .list-item-single{
  padding-left: 15px !important;
  padding-right: 0px !important;
  padding-top: 20px !important;
  padding-bottom: 15px !important;
  background-color: black;
  margin-bottom: 20px !important;
  width: calc(100% - 20px);
}

.single-order .id-col{
  width:20%!important;
}

.single-order .text-col{
  width:80%!important;
  padding-top:0px;
  padding-left:7px;
  padding-right:7px;

  .advantages {
    padding-top: 15px;
  }

  h4 {
    margin: 0px 0px 5px 0px;
    text-align: left;
    color: white;

    span {
      float: right;
    }
  }
}

.single-order .button-col{
  width:25%;
}

.single-order .order-time{
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom:12px;
}

.single-order .order-number{

}

.single-order .order-ticket{
  background: #23aa02;
  width:100%;
  max-width: 128px;
  height:80px;
  color:white;
  font-size: 24px;
  font-weight: 900;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.single-order .green-button{
  height:120px;
  width:120px;
  color:white;
  background-color: #ee7101;
  font-weight: 600;
  font-size: 16px;
  padding: 14px;
  text-transform: uppercase;
  border-radius: 6px;
}

.single-order .list-item-single-outer a{
  color:black;
}

.single-order .order-small{
  text-align: center;
  padding-top:20px!important;
  padding-bottom:14px!important;
}

.single-order .order-large{
  text-align: center;
  padding-top:20px!important;
  padding-bottom:14px!important;
}

.single-order .order-others{
  text-align: center;
  padding-top:20px!important;
  padding-bottom:14px!important;
}

.single-order .red-bg{
  background-color: black;
}

.single-order .text-align-center{
  text-align: center;
}

.single-order span.text{
  color:white;
  font-size: 14px;
  font-weight: 600;
}

.single-order span.number{
  color:white;
  font-size: 24px;
  font-weight: 600;
}

.single-order .white-left{
  border-left:1px solid white;
}

.single-order .white-right{
  border-right:1px solid white;
}

.single-order .white-top{
  border-top: 1px solid white;
}

.single-order .col-33{
  width:33.33%;
}

.single-order .color-white{
  color:white;
}

.single-order .order-number{
padding-bottom:24px!important;
padding-top:32px!important;
font-weight: 900;

  h1 {
    font-size: 45px;
    margin: 10px 0px 10px 0px;
  }

  h4 {
    margin: 0;
    padding: 0;
  }

  .lion-container {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h3 {
      margin: 10px 0px 0px 0px;
    }

    .lion {
      background-image:url("../img/JJB-LEVEL-GOLDEN-LION-SOLID.svg");
      background-size: cover;
      background-position:center center;
      background-repeat:no-repeat;
      width:65px;
      height:65px;
      margin: 0px 0px 5px 0px;
    }
  }

  &.advantages {
    display:flex;

    .lion {
      background-image:url("../img/JJB-LEVEL-GOLDEN-LION-SOLID.svg");
      background-size: cover;
      background-position:center center;
      background-repeat:no-repeat;
      width:90px;
      height:90px;
      margin: 0px 0px 5px 0px;
    }

  }

  &.user-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    h2 {
      text-align: left;
      text-transform: uppercase;
      margin:0;
    }

    h3 {
      margin:0;
      text-align: left;
    }

    h4 {
      margin: 5px 0px 10px 0px;
      text-align: left;
    }

  }

}

.single-order .button-container {

  .list-container {
    padding-top: 0px;
    padding-bottom: 20px;
    display:flex;
    flex-wrap: wrap;
  }

  .title h3{
    text-transform: uppercase;
    margin: 0;
  }

  .button {
    background: #23aa02;
    width: 200px;
    color:white;
    font-size:14px;
    font-weight: 900;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0;
    padding: 30px;
    margin: 0px 15px 15px 0px;
    text-transform: none;

    &.passive {
      background: #485243;
      color: #5f685b;
    }
  }

}

.advantages .inner-container{
  padding: 50px 0px 50px 0px !important;
}

.single-order .customer-name{
text-transform: uppercase;
font-weight: 600;
}
.single-order .customer-number{

}

.single-order .customer-phone{
  padding-bottom:32px!important;
}

.single-order .order-details-nav{
  height:100px;
  font-weight: 600;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 17px;
  background-image:url("../img/arrow_left.svg");
  background-repeat: no-repeat;
  background-position: 15px 50%;
}

.single-order .edit-order-button.block {
  width: 100%;
  background-color:rgb(112,164,0);
  color: white;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  border-radius: 8px;
}
.single-order .text-col{
  width:80%!important;
}

.single-order.header {
  padding-bottom: 50px;
}

.demo-popup.modal-in {
  max-width: 530px;
}

.popup {
  border-radius: 30px !important;
}

@media (min-height: 630px) and (min-width: 630px){
  .popup {
    margin-right: -50px!important;
  }
}

.confirm-modal {
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: black;
  margin-right: auto;
  margin-left: auto;
  position: absolute;
  position: sticky;
  bottom: 15%;
  border-radius: 30px;
}

.confirm-modal .close {
  background-image:url("../img/close.svg");
  background-size: cover;
  background-position:center center;
  background-repeat:no-repeat;
  width:15px;
  height:15px;
  position: absolute;
  top: 20px;
  right: 20px;
}

.confirm-modal .row{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  text-align: center;
  padding-top: 50px;
  background: url("../img/viidakko_web.svg") -480px -100px no-repeat, url("../img/viidakko_web2.svg") center bottom repeat;
}

.confirm-modal .modal-heading{
  font-family: 'Open Sans', sans-serif;
  font-weight: 800;
  font-size: 18px;
  color: white;
  text-transform: uppercase;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.confirm-modal .modal-text{
  color: white;
  font-family: 'Open Sans', sans-serif;
  font-weight: 800;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.confirm-modal .green-button{
  font-family: 'Open Sans', sans-serif;
  font-weight: 800;
  font-size: 16px;
  background-color: #2b6032;
  height:40px;
  width:calc(50% - 25px);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color:white;
}

.confirm-modal .radio-buttons {
  display: flex;
  justify-content: center;
}

.confirm-modal .radio-buttons .list {
  width: 100%;
  max-width: 450px;
}

.confirm-modal .radio-buttons .list ul{
  background: transparent;
}

.confirm-modal .radio-buttons .list .item-text{
  color: white;
  -webkit-line-clamp: initial !important;
  text-overflow: unset !important;
  overflow:visible!important;
  text-align: left;
  padding-left: 15px;
}

.confirm-modal .radio-buttons .list .icon-radio{
  margin-right: 0px!important;
  background: white;
  border: white;
}

.confirm-modal .radio-buttons .list input[type="radio"]:checked ~ .icon-radio{
  margin-right: 0px!important;
  background: #23aa02;
  border: #23aa02;
}

.confirm-modal .radio-buttons .list input[type="radio"]:checked ~ .icon-radio:after{
  background: transparent;
  color: white;
  content: '\2713';
  top: 3px;
  left: 9px;
}

label.item-radio input[type="radio"]:checked ~ .icon-radio:after,

.confirm-modal .radio-buttons .list .item-radio{
  overflow:visible!important;
}

.confirm-modal .radio-buttons .list .item-inner{
  padding-bottom: 15px;
}

.confirm-modal .media-list {
  padding: 10px 0px 0px 0px;
  margin: 0;
}

.confirm-modal  p{
  margin: .5em 0;
}

.modal-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom:20px;
  margin-top:25px;
}

.demo-popup.modal-in {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background: transparent;
}

.basketmodal{
  height:94px;
  width:calc(100% - 30px);
  padding-left:15px;
  padding-right:15px;
  padding-top:10px;
  box-shadow: 0 1px 1px rgba(0,0,0,0.11),
    0 1px 1px rgba(0,0,0,0.05),
    0 1px 2px rgba(0,0,0,0.01),
    0 0px 4px rgba(0,0,0,0.21),
    0 0px 4px rgba(0,0,0,0.01);
}

.basketmodal-outer.outer-container {
    width: 100%;
    position: sticky;
    bottom: 90px;
    z-index: 9999;
    pointer-events: none;
    display: none;
}

.basketmodal-button{
  background-color: #70A401;
  color: white;
  width: 100%;
  height: 34px;
  margin-bottom: 12px;
  margin-top: 12px;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 800;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.basketmodal-multiplier{
  font-family: 'Open Sans', sans-serif;
  font-weight: 800;
  font-size: 16px;
}

.basketmodal-sum{
  font-family: 'Open Sans', sans-serif;
  font-weight: 800;
  font-size: 16px;
}

.basketmodal-discount{
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 12px;
}

.basketmodal .half-width{
  height:40px;
}

.basketmodal .half-width.left-align{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.basketmodal .half-width.right-align {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.basketmodal-outer.fade {
  opacity: 0;
}

.basketmodal-outer.active {
  display: block;
  pointer-events: all!important;
  opacity: 1;
  -webkit-transition:opacity 500ms ease-out;
  -moz-transition:opacity 500ms ease-out;
  -o-transition:opacity 500ms ease-out;
  transition:opacity 500ms ease-out;
}
